import React, { useState } from 'react'
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import "./Introduction.css";


function Introduction(props) {
    return (
        <div style={{backgroundColor: "#051c2c"}}>
            <BackgroundImage
                Tag="section"
                className="background-about-container"
                fluid={props.backImg.imageFile.childImageSharp.fluid}
                backgroundColor={`#040e18`}
            >
                <div>
                    <h1>{props.title}</h1>
                </div>
            </BackgroundImage>
        </div>
    )
}

export default Introduction;
