
import React from "react"
import MainMenu from "./MainMenu"
import FooterMenu from "./FooterMenu"
import { Helmet } from "react-helmet"


const Layout = ({ children, metaData , header , footer , services }) => (
	<div>
        {console.log(services)}
		{metaData && (
			<Helmet>
			<html lang={"fr"} />
			<title>{metaData.title}</title>
			<meta name="description" content={metaData.description} />
			<link rel="canonical" href={metaData.canonical} />
			<meta
				name="viewport"
				content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
			/>

			<meta property="og:title" content={metaData.fbTitle} />
			<meta property="og:description" content={metaData.fbDescription} />
			<meta property="og:url" content={metaData.canonical} />
			<meta property="og:type" content={metaData.fbType} />
			<meta property="og:locale" content={metaData.locale} />
			<meta property="og:image" content={metaData.image} />
			<meta property="og:image:width" content="598" />
			<meta property="og:image:height" content="399" />

		</Helmet>
		)}
		
		<MainMenu  headerMenu={ header } services={ services } locale={"el"}  />
		{children}
		<FooterMenu footerMenu={ footer } />
        
	</div>
  
)

export default Layout
