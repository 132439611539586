import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import htmlDecode from "html-entities-decoder"
import moment from "moment"
import 'moment/locale/el'

moment.locale('el')

const PostCol2 = (props) => (
    <div className={props.layout === "solo" ? "w-full md:w-1/2 pt-12 md:p-6 flex flex-col flex-grow flex-shrink md:max-w-md relative post-col" : "w-full md:w-1/2 pt-12 md:p-6 flex flex-col flex-grow flex-shrink relative post-col"}>
        <div className="flex-1 bg-white overflow-hidden ">
            <Link to={`/${props.post.categories.nodes[0].slug}/${props.post.slug}/`} className="flex flex-wrap no-underline hover:no-underline">
                {
                    props.post.featuredImage
                        ?
                        <Img fluid={props.post.featuredImage.imageFile.childImageSharp.fluid} alt={props.post.featuredImage.altText} className="h-full w-full pb-6" style={{ height: "240px" }} />
                        :
                        null
                }
                <div className="w-full font-bold text-xl text-gray-900 px-6 pb-2 mt-4 art-name">{htmlDecode(props.post.title)}</div>
                <div className="pt-4"  style={{color:"darkgrey"}}>
                    {/* <span>{props.post.author.lastName}</span>
                    <span className="px-2">/</span> */}
                    <span className="uppercase">{props.post.categories.nodes[0].name.replace(/ά/g,'α').replace(/έ/g,'ε').replace(/ή/g,'η').replace(/ί/g,'ι').replace(/ώ/g,'ω').replace(/ό/g,'ο').replace(/ύ/g,'υ')}</span>
                    <span className="px-2">/</span>
                    <span>{props.post.postRT.postReadTime} ΛΕΠΤΑ</span>
                </div>
                <div className="text-gray-800 font-serif text-lg" dangerouslySetInnerHTML={{ __html: props.post.excerpt }} />
            </Link>
        </div>
        <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden">
            <div className="flex items-center justify-between">                
                <div className="date-container">
                    <div>{moment(props.post.modified).format("DD")}</div>
                    <div>{moment(props.post.modified).format("MMM")}</div>
                </div>

            </div>
        </div>
    </div>
)

export default PostCol2
