import React, { useState } from "react"
import axios from "axios"


function SubscribeFull(props) {
    const [hasError, setHasError] = useState(false);
    const [hasSubscribed, setHasSubscribed] = useState(false)
    const [emailError, setEmailError] = useState(false)

    function subscribe() {
        let formData = new FormData();

        let email = !props.extraText ? document.getElementsByName("EMAIL")[0].value : document.getElementsByName("EMAIL")[1].value ;

        console.log(email)

        formData.append('subEmail', email);
        if (validateEmail(email)) {
            axios.post(`https://f2.enimeris.com/wp-json/myplugin/v1/addSubscriber`, formData)
                .then(function (response) {
                    console.log(response)
                    if (response.data) {
                        console.log("MESSASASASAS")
                        setHasSubscribed(true)
                        setEmailError(false)
                    } else {
                        setHasError(true)
                        setEmailError(false)
                    }
                    // handle success
                })
                .catch(function (error) {
                    // handle error
                })
        } else {
            // setEmailError(true)
        }
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    return (
        <div className={`font-sans bg-white rounded text-center shadow-lg ${props.classes ? props.classes : "my-8 p-4 md:p-24 md:mx-12"} `}>
            {!hasSubscribed
                ?
                <div>
                    <div>
                        <h2 className="font-bold break-normal text-2xl md:text-4xl pb-4" style={{ color: "#143b68" }}>Εγγραφείτε στο Blog μας </h2>
                        { props.extraText && (
                            <p>Ενημερωθείτε πρώτοι για τα τρέχοντα θέματα και δείτε τις συμβουλές μας για λογιστικά, φορολογικά, επιχειρηματικότητας και αγοράς εργασίας θέματα.</p>
                        )}
                        <div className="max-w-xl mx-auto p-1 pr-0 block md:flex md:flex-wrap items-center">
                            <input id="email" type="email" name="EMAIL" placeholder="mail@example.com" required className={` ${props.classes ? "w-full mb-4 " : "md:w-1/2 w-full"} appearance-none rounded shadow p-3 text-gray-600-contr mr-2 focus:outline-none subs-input-border`} />
                            <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" tabIndex="-1" value="" /></div>
                            <button onClick={subscribe} name="subscribe" className={`  ${props.classes ? "w-full" : " w-full md:w-1/3 "} mt-4 text-center md:mt-0 block md:inline-block appearance-none text-white text-base font-semibold tracking-wider uppercase py-4 rounded shadow cursor-pointer bg-color `} >
                                Εγγραφη
                            </button>
                            {hasError && (
                                <div className="pt-4 text-center w-full" style={{ color: "red" }}>
                                    Το email που δώσατε βρίσκεται ήδη στη λίστα συνδρομητών μας.
                                </div>
                            )}
                            {emailError && (
                                <div className="pt-4 text-center w-full" style={{ color: "red" }}>
                                    Το email που δώσατε δεν είναι έγκυρο.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                :
                <div className="font-bold break-normal text-2xl md:text-4xl" style={{ color: "#143b68" }}>
                    Ευχαριστούμε για την εγγραφή σας στη λίστα συνδρομητών μας.
                </div>
            }
        </div>
    )
}

export default SubscribeFull
